// Import React and Dependencies
import React from 'react';
import ReactDOM from 'react-dom';

// Import Router
import { BrowserRouter as Router } from 'react-router-dom';

// Import the App
import App from './App';


// ReactDOM.render(<App />, document.getElementById('root'));
const rootElement = document.getElementById('root');
ReactDOM.hydrate(
  <Router>
    <App />
  </Router>,
  rootElement
);