// Dependencies
import React, { useState } from 'react';
import axios from 'axios';

// Style
import '../styles/ContactPage.css';

const Contact = function() {
  let [user, setUser] = useState({
    name: '',
    email: '',
    message: ''
  });
  const handleChanges = event => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    console.log('attempt')
    if( user.name && user.email ){
      axios
      .post('https://hasbeen.herokuapp.com/api/mail/support', user)
      .then( res => {
        console.log(res)
        resetForm()
      })
      .catch( err => {
        console.log(err)
      })
    }
  };

  const resetForm = () => {
    setUser({ name: '', email: '', message: ''})
  };

  return (
    <>
      <div className='contact_container'>
        <div className="contact_content">
          <h2>Contact Form</h2>
          <div className='contact_divider'/>
          <div className='contact_form'>
            <form method='POST' action=''>  
              <div className='contact_input_split'>
                <div className="contact_input_title">
                  <h3 htmlFor="form_name">Name:</h3>
                </div>
                <input onChange={handleChanges} name="name" className="contact_input_field" type="text" />
              </div>
              <div className='contact_input_split'>
                <div className="contact_input_title">
                  <h3 className='contact_title' htmlFor="form_email">Email:</h3>
                </div>
                <input onChange={handleChanges} name="email" className="contact_input_field" type="text" />
              </div>
              <div className='contact_input_split'>
                <div className="contact_input_title">
                  <h3 className='contact_title' htmlFor="form_message">Message:</h3>			
                </div>
                <textarea onChange={handleChanges} className='contact_input_field' name="message" rows="9" cols="20"></textarea>
              </div>
            
              <button type='submit' onClick={handleSubmit} className="contact_submit">Submit</button>		
            </form>
          </div>
        </div>
        {/* <div className='contact_status'>
          {attempt ? <p className='success'>Success</p> : <p className='fail'>Failed</p>}
        </div> */}
      </div>
    </>
  )
}

export default Contact;