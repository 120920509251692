// Dependencies
import React from 'react';
import { Route } from 'react-router-dom';

// Components
import Crew from '../views/CrewPage';
import Photos from '../views/PhotosPage';
import Landing from '../views/LandingPage';
import Contact from '../views/ContactPage';
import Preview from '../views/PreviewPage';
import Thanks from '../views/ThanksPage';


const Routes = () => {
  return (
    <>
      <Route exact path="/" component={Landing} />
      <Route path="/awards" component={Crew} />
      <Route path="/preview" component={Preview} />
      <Route path="/gallery" component={Photos} />
      <Route path="/thanks" component={Thanks} />
      <Route path="/contact" component={Contact} />
    </>
  );
};

export default Routes;