// Dependencies
import React from 'react';
import { NavLink } from 'react-router-dom';

//  Style
import './NavBar.css';

const NavBar = function() {
  return(
    <nav className='nav_container'>
        <NavLink to='/'>Home</NavLink>
        <NavLink to='/awards'>Awards</NavLink>
        <NavLink to='/preview'>Preview</NavLink>
        <NavLink to='/gallery'>Gallery</NavLink>
        <NavLink to='/thanks'>Special Thanks</NavLink>
        <NavLink to='/contact'>Contact</NavLink>
    </nav>
  )
}

export default NavBar;