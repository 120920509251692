// Dependencies
import React from 'react';

// Import styling for the overall app
import './styles/App.css';

// Components
import NavBar from './Navigation/NavBar';
import Routes from './routes/Routes';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <NavBar />
      </header>
      <Routes />
    </div>
  );
}

export default App;
