// Dependencies
import React from 'react';

// Style
import '../styles/ThanksPage.css';

const Thanks = function() {
return(
  <div className='thanks_container'>
    <h2>
      Special Thanks
    </h2>
    <div className='thanks_rows'>
      <p>
        Gus Solomons Jr.      <br/>
        Austin Pendleton      <br/>
        Caroline Aaron        <br/>      
        Stephen De Rosa       <br/>
        Mary Francina Golden  <br/>
        Janine Hegarty        <br/>
        Jacqueline M.         <br/>
        Nadira Jana           <br/>
        David Chase           <br/>
        Chris Martin          <br/>
        Carl Kissin           <br/>
        Edith Meeks           <br/>
        HB Studio             <br/>
        Meredith Glisson      <br/>
        Sarah Magraw          <br/>
        Carl Kissin           <br/>
        Rebecca Robertson     <br/>
        Cindy and Joseph Martz  <br/>
        Jane L. Watson        <br/>
        Laurence Wallace      <br/>
        Eleonora Cacciola     <br/>
        Rebecca Robertson     <br/>
        Judy Levine           <br/>
        Hila Ben Gera         <br/>
        Cheryl Bear           <br/>
        Michael Barringer     <br/>
        Tyrone Smith          <br/>
        Alessia Valfredini    <br/>
        Shira Grossman        <br/>
        Mike Nichols          <br/>
        Elaine May            <br/>
        Wallace Shawn         <br/>
        Andre Gregory         <br/>
      </p>
      <p>
        Sophia Coppola        <br/> 
        Francis Ford Coppola  <br/>
        Werner Herzog         <br/>
        Zana Markleson        <br/>
        Jim Jarmusch          <br/>
        Wes Anderson          <br/>
        Tarsem Singh          <br/>
        Louis Malle           <br/>
        Joseph Losey          <br/>
        Luis Bunuel           <br/>
        Thomas Earl Petty     <br/>
        Lei Zhou              <br/>
        Lauren Hobbs          <br/>
        Lauren Swan Potras    <br/>
        Jim Kempner Fine Arts <br/>
        Daniel Libin          <br/>
        Pascal Boulet Gercourt  <br/>
        Leonard Hammer        <br/>
        Susan Coronel         <br/>
        Chrystie Sherman      <br/>
        Lisa Rosen            <br/>
        Doris McCarthy        <br/>
        Joel Kramer           <br/>
        Haithem Oeslati       <br/>
        Sarah Hennigan        <br/>
        Juana Zavalia         <br/>
        Marlene Fusaris       <br/>
        Dr. David Miller      <br/>
        Keith Moskowitz       <br/>
        Miklos Fedak Soylom   <br/>
        Jim Fenton            <br/>
        Dr. Ruth Bevan        <br/>
        Martin/Rashi/Hillel   <br/>
        Murray/Phyllis
      </p>
    </div>
    <div className='thanks_footer'>
      <p>
        The film is dedicated to George Morfogen( 1933-2019)
      </p>
    </div>
  </div>
)
}

export default Thanks;