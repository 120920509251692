// Dependencies
import React, {useState} from 'react';

// Style
import '../styles/PhotosPage.css';

const imgUrls = [
  './Images/0.png',
  './Images/1.png',
  './Images/2.png',
  './Images/3.png',
  './Images/4.png',
  './Images/5.png',
  './Images/6.png',
  './Images/7.png',
  './Images/8.png',
  './Images/9.png',
  './Images/10.png',
  './Images/11.png',
  './Images/12.png',
  './Images/13.png',
  './Images/14.png',
  './Images/15.png',
  './Images/16.png'
]

const ImageSlide = ({ url }) => {
  return (
    <img className="image-slide" src={url} alt='carousel element'/>
  );
}

const Arrow = ({ direction, clickFunction, glyph }) => (
  <div
    className={ `slide-arrow ${direction}` }
    onClick={ clickFunction }>
    { glyph }
  </div>
);

const Photos = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [timer, setTimer] = useState(false);
  const lastIndex = imgUrls.length - 1;

  const previousSlide = () => {
    const restIndex = currentIndex === 0;
    const index = restIndex ? lastIndex : currentIndex - 1;
    
    setCurrentIndex(index);
    // setTimer(false);
    // setTimeout(() => {setTimer(true);},5000);
  }
  
  const nextSlide = () => {
    const restIndex = currentIndex === lastIndex;
    const index = restIndex ? 0 : currentIndex + 1;
    
    setCurrentIndex(index);
    // setTimer(false);
    // setTimeout(() => {setTimer(true);},5000);
  }
  
  // useEffect(() => {
  //   setTimeout(function(){
  //     if(timer){
  //       nextSlide();
  //     }
  //   }, 5000)
  // }, [currentIndex]);

  return (
    <div className='carousel'>
      <Arrow direction="left" clickFunction={ previousSlide } glyph="&#9664;" />

      <ImageSlide url={ imgUrls[currentIndex] } />

      <Arrow direction="right" clickFunction={ nextSlide } glyph="&#9654;" />
    </div>
  )
}

export default Photos;