// Dependencies
import React from 'react';

// Style
import '../styles/PreviewPage.css';

const Preview = function() {
  return(
    <div className='preview_container'>
      {
      window.innerWidth > 800 ?
      <iframe title="Movie Preview" width="1440" height="720" src="https://youtube.com/embed/JkBimrWu2L4" allowFullScreen></iframe>
      :
      <iframe title="Movie Preview" width="300" height="144" src="https://youtube.com/embed/JkBimrWu2L4" allowFullScreen></iframe>
      }
      {/* <video width='1980' height='1020' controls>
        <source src='./For_Monte/Tazo_Preview_#2.mp4' type='video/mp4'></source>
      </video> */}
    </div>
  )
}

export default Preview;