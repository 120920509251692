// Dependencies
import React from 'react';

// Style
import '../styles/Landing.css';

const Landing = function() {
  return(
    <div className='landing_container'>
      <img src='./landing.png' alt='landing still' />
      <div className='landing_overlay'>
        <img src='./olffaward1.png' alt='award badge o1ff' />
        <img src='./award8w.png' alt='award badge io films' />
        <img src='./award11w.png' alt='award badge liaff' />
        <img src='./semifinalist1.png' alt='award badge for film of semi finalist status' />
      </div>
    </div>
  )
}

export default Landing;