// Dependencies
import React from 'react';

// Style
import '../styles/CrewPage.css';

const Crew = function() {
  return(
    <div className='crew_container'>
      <h1>Has-Been/ Never-Was</h1>
      <h2>
        A Feature Film Comedy<br />
        Eighty-Nine Minutes
      </h2>
      <div className='crew_top'>
        <div>
          <h3>
            <br />
            Crew
          </h3>
          <p>
            Written And Directed By M.S. Kerr<br />
            Director Of Photography - Bryce Reidesel<br />
            Edited By M.S. Kerr and Bryce Reidesel<br />
            Assistant Director - Mia Christo<br />
            Score By Slobodan Bogdaovic<br />
            Sound By Jenny Hodo and Sky Zak<br />
          </p>
        </div>
        <div className='verticalBar'></div>
        <div>
          <h3>
            <br />
            Cast<br />
          </h3>
          <p>
            Tristan Harness As Linda<br />
            Jim Kempner As Oscar<br />
            M. S. Kerr As Rudy<br />
            Keegan McDonald As Max<br />
            A 68 Steps Production<br />
            Produced By M.S. Kerr And Mia Christo<br />
            Copyright 2020
          </p>
        </div>
      </div>
      <div className='crew_bot'>
        <div>
          <img src='./olffaward2.png' alt='award badge o1ff' />
          <img src='./image.png' alt='award badge io films' />
          <img src='./bs.png' alt='award badge black swan' />
          <img src='./award7.png' alt='award badge liaff' />
          <img src='./semifinalist2.png' alt='award badge for film of semi finalist status' />
        </div>
        <div>
          <img src='./award9.jpg' alt='award badge new film makers' />
          <img src='./award6.png' alt='award badge boston film' />
          <img src='./best_actor.jpg' alt='award badge new york film makers' />
          <img src='./indie_film.jpg' alt='award badge new york film makers' />
          <img src='./art_films_monthly.jpg' alt='award badge new york film makers' />
        </div>
        <div>
          <img src='./adif.png' alt='award badge actor and director awards' />
          <img src='./bliaf.png' alt='award badge blow up int arthouse' />
          <img src='./dciff.png' alt='award badge director cut intl film festival' />
          <img src='./liff.png' alt='award badge louisville intl festival of film' />
          <img src='./nycftf.png' alt='award badge new york film and television' />
        </div>
        <div>
          <img src='./award13.png' alt='award badge neorealism select' />
          <img src='./award11.png' alt='award badge fine arts festival' />
          <img src='./afaa.png' alt='award badge american film arts' />
          <img src='./award12.png' alt='award badge master of cinema' />
          <img src='./award14.png' alt='award badge neorealism narrative' />
        </div>
      </div>
    </div>
  )
}

export default Crew;